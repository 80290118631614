import { useEffect, useState } from 'react';
import { API } from '@api';


export const useInfoData = (query) => {
    const [infoData, setInfoData] = useState(null);

    useEffect(() => {
        API.getInfo(query).then((data) => setInfoData(data));
    }, [setInfoData]);

    return infoData
};
