import axios from 'axios';
import { debounce } from '../helpers/debounce';
import { checkEmailValidity } from '../helpers/checkEmailValidity';

const instance = axios.create({
    baseURL: process.env.REACT_APP_REQUEST_PATH,
    withCredentials: true,
});

export const API = {
    auth: (number) => {
        return instance
            .get(`user/auth/${number}`)
            .then((response) => response.data);
    },
    getAuthData: () => {
        return instance
            .get('/user/profile')
            .then((response) => response.data)
            .catch(() => null);
    },
    logout: () => {
        return instance
            .post('/user/logout')
            .then((response) => response.data)
            .catch(() => null);
    },
    getSuccessData: (number) => {
        return instance
            .get(`order/info/${number}`)
            .then((response) => response.data);
    },
    sendOrder: (data) => {
        return instance
            .post(
                'order',
                { ...data },
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            )
            .then((response) => response.data);
    },
    getInfo: async (query) => {
        return instance.get(`products/info${query || ''}`).then((response) => response.data);
    },
    getCategories: async () => {
        return instance.get('categories').then((response) => response.data);
    },
    getProducts: async () => {
        return instance
            .get('products')
            .then((response) => response.data.sort(() => Math.random() - 0.5));
    },
    getPromoData: async (slug) => {
        return instance.get(`promo/${slug}`).then((response) => response.data);
    },
    sendEmail: (email) => {
        debounce(function () {
            email.replace(/^(0|\+44) */, '');
            email.toLowerCase();
            const { hostname } = window.location;
            const apiPath =
        window.location.protocol +
        `//${hostname}` +
        (hostname === 'localhost' ? ':' + window.location.port : '');

            if (checkEmailValidity(email)) {
                fetch(apiPath + `/email?email=${email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                }).catch((e) => {
                    throw new Error(e);
                });
            }
        }, 500)();
    },
    getPromoTrackers: async (query) => {
        return instance.get(`promo${query}`).then((response) => response.data);
    },
    getPaymentSystems: (query) => {
        return instance
            .get(`/available-payment-systems${query || ''}`)
            .then((response) => response.data)
            .catch(() => null);
    },
};
