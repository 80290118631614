import { useState, useEffect } from 'react';


export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(key && defaultValue ? defaultValue : localStorage.getItem(key) || null);

    useEffect(() => {
        if (key) {
            if (defaultValue) {
                localStorage.setItem(key, defaultValue)
                setValue(defaultValue)
            } else {
                setValue(localStorage.getItem(key) || null)
            }
        }
    }, [value, key, defaultValue]);

    return [value, setValue];
};
